 .success-custom-modal {
  width: 400px;
 }
 .success-modal {
  .success-modal-icon{
      width: 90px;
      height: 90px;
  }
  .btn {
    padding: 0.77rem 0.75rem;
    font-family: "Urbanist", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.03em;
    text-align: center;
  }
  p {    
    font-family: 'Urbanist', sans-serif;    
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #000000;
  }
}