.download-receipt-custom-modal{
  max-width: 900px;
  width: 600px;
}
.close-modal-icon{
  color: #704DE7;

}
.receipt-logo-con{
    width: 300px;
}
.receipt-logo-img{
    width: 100%;
}
.reciept-con{
  font-family: 'Urbanist', sans-serif;
  letter-spacing: 0.03em;
  color: #000000;
  text-align: center;
  .title{
      font-size: 25px;
      font-weight: 700;
      line-height: 24px;
      color: #000000;
  }
  .hor-line{
      border: 1px solid #EDEDED;
  }
  .case-id{
      font-size: 20px;
      font-weight: 500;
      line-height: 19px;
  }
  .step-body-con{
      border: 2px dashed #BBBBBB;
      border-radius: 5px;
  }
  .case-id-long{
      font-size: 16px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0.03em;
  }
  .case-date{
      font-size: 16px;
      font-weight: 500;
      line-height: 14px;
      color: #737373;
  }
  .process-title{
      font-size: 16px;
      font-weight: 400;
      line-height: 14px;
  }
  .process-name{
      font-size: 16px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #704DE7;
  }
  .step-num{
      padding: 2.5px 3px;
      background-color: #704DE7;
      color: white;
      border-radius: 100%;
      font-size: 14px;
      }
  .process-payment{
      font-size: 16px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0.02em;
  }
  .process-fee{
      font-size: 16px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0.02em;
  }
  .total-payment{
      font-size: 22px;
      font-weight: 700;
      line-height: 22px;
      color: #000000;
  }
  .final-payment-digits {
      font-size: 22px;
      font-weight: 700;
      line-height: 22px;
      color: #704DE7;
  }
}

@media (max-width:  700px) {
  .download-receipt-custom-modal{
    width: 450px;
  }
}