.case-detail-container {
  padding: 1rem;
  .case-detail-main-container {
    margin-bottom: 1rem;
    font-family: "Urbanist", sans-serif;
    text-align: left;
    .cm-header-con{
      display: flex;
      justify-content:space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .case-detail-form-fields-container {
        min-width: 50%;
        padding: 1rem;
        font-weight: 600;
        p {
            font-size: 14px;
            color: #704de7;
        }
        div {
            color: #222222;
            font-size: 18px;
        }
    }
    .cd-header-btn{
      color: #F69E1A;
      border-color: #F69E1A;
      padding: 0.65rem 1.7rem;
      width: 100%;
      &:hover{
        color: #fff;
        background-color: #F69E1A;
      }
      min-width: 128px;
      
    }
}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
  .case-detail-container {
    .case-detail-main-container {
      .cm-header-con{
        justify-content: flex-start;
      }
      .case-detail-form-fields-container {
        min-width: 50%;
      }    
  }
  }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  .case-detail-container {
    .case-detail-main-container {
      .cm-header-con{
        justify-content: space-between;
      }
      .case-detail-form-fields-container {
        min-width: auto;
      }   
      .customer-details-header-fields {
        width: 33.33%;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 1600px) { 
  .case-detail-container {
    .case-detail-main-container {
      .cm-header-con{
        justify-content: space-between;
      }
      .case-detail-form-fields-container {
        min-width: auto;
      }   
      .customer-details-header-fields {
        width: 25%;
      }
    }
  }
}