.bv-text-input {
    .input-group:focus-within {
        border: 1px solid #704de7;
    }
    .input-group {
        border: 1px solid #704de7;
    }
    .input-group:focus-within *:focus {
        outline: 0;
    }
    // .input-group input + *,
    // .input-group span + * {
    // border-left: none;
    // }
    &:not(.hide-shadow) {
        box-shadow: 0px 3px 12px #706e6e29;
        // input,
        // select {
        //     box-shadow: 0px 3px 12px #706e6e29;
        // }
    }
    input,
    select {
        border-radius: 5px;
        background-color: #fff;
        padding: 12px;
        font-size: 1rem;
        // border-color: transparent;
        border: none;
    }
    .appended-icon {
        border-radius: 5px;
        background-color: #fff;
        padding: 12px;
        border-color: transparent;
        box-shadow: 0px 3px 12px #706e6e29;
        cursor: pointer;
        img {
            width: 15px;
            height: 15px;
        }
        // &:focus {
        //     border-right: none;
        //     border-color: #704DE7;
        // }
    }
    .appended-icon-pre{
        font-size: 1rem;
        font-weight: 600;
        border: none;
        background-color: #eff2f7;
        opacity: 1;
        border-radius: 5px;
    }
    select {
        padding-right: 30px !important;
    }
    input {
        &[type="date"] {
            padding-right: 12px !important;
        }
    }
    // &:not(.hide-icon) {
    //     input {
    // padding-left: 47px;
    // padding-right: 47px;
    // &::placeholder {
    //     color: #2e2e30;
    // }
    // &[type="email"] {
    //     background: url("../../assets/images/envelop-icon.png") no-repeat 20px center #fff;
    // }
    // &[type="password"] {
    //     background: url("../../assets/images/aiAPaiT/lock-icon.png") no-repeat 20px center #fff;
    // }
    //     }
    // }
    input.form-control {
        &::-webkit-input-placeholder {
            font-family: "Urbanist", sans-serif;
            font-weight: 500;
            font-size: 1rem;
            line-height: 19px;
            letter-spacing: 0.03em;

            color: rgba(0, 0, 0, 0.5);
        }
        &:focus {
            border: none;
            border-color: transparent;
        }
    }

    textarea.form-control {
        // min-height: 256px;
        // resize: none;
        // background-color: #eef9ff;
        // border-color: #eef9ff;
        font-family: "Urbanist", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0.03em;
        color: rgba(0, 0, 0, 0.8);
        &::placeholder {
            // color: #000;
            font-family: "Urbanist", sans-serif;
            font-weight: 500;
            font-size: 1rem;
            line-height: 19px;
            letter-spacing: 0.03em;

            color: rgba(0, 0, 0, 0.5);
        }
    }
}
