.dept-detail-container {
  padding: 1rem;
  .dept-detail-main-container {
    margin-bottom: 1rem;
    font-family: "Urbanist", sans-serif;
    text-align: left;
    .cm-header-con{
      display: flex;
      justify-content:space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .dept-detail-form-fields-container {
        min-width: 50%;
        padding: 1rem;
        font-weight: 600;
        p {
            font-size: 14px;
            color: #704de7;
        }
        div {
            color: #222222;
            font-size: 18px;
        }
    }
    .cd-header-btn{
      color: #F69E1A;
      border-color: #F69E1A;
      padding: 0.82rem 1.7rem;
      &:hover{
        color: #fff;
        background-color: #F69E1A;
      }
    }
    .view-countries-btn,
    .edit-btn,
    .suspend-btn{
      min-width: 169px;
      min-height: 50px;
    }
    .view-countries-btn{
      padding: 0.82rem 1.7rem;
    }
    .edit-btn{
      padding: 0.82rem 4.5rem;
    }
    .suspend-btn{
      padding: 0.82rem 3.2rem;
      min-width: 169px;
      min-height: 50px;
    }
}
}
.dept-header-right-con{
  align-self: flex-start;

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
  .dept-detail-container {
    .dept-detail-main-container {
      .cm-header-con{
        justify-content: flex-start;
      }
      .dept-detail-form-fields-container {
        min-width: 33.33%;
      }    
      .dpt-header-left{
        .dept-detail-form-fields-container {
          min-width: 50%;
        }    
      }
  }
  }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  .dept-detail-container {
    .dept-detail-main-container {
      .cm-header-con{
        justify-content: space-between;
      }
      .dpt-header-left{
        .dept-detail-form-fields-container {
          min-width: 50%;
        }    
      }
      .dept-detail-form-fields-container {
        min-width: 33.33%;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }    
      .view-countries-btn{
        padding: 0.82rem 1.2rem;
      }
      .edit-btn{
        padding: 0.82rem 4rem;
      }
      .suspend-btn{
        padding: 0.82rem 2.8rem;
      }
    }
  }
 }

 @media (min-width: 1600px) {
  .dept-detail-container {
    .dept-detail-main-container {
      .cm-header-con{
        justify-content: flex-end;
      }
      .dpt-header-left{
        .dept-detail-form-fields-container {
          min-width: 50%;
        }    
      }
      .dept-detail-form-fields-container {
        min-width: inherit ;
        padding-right: 0rem;
        padding-left: 0rem;
      }    
      .view-countries-btn{
        padding: 0.82rem 1.7rem;
        margin-right: 1rem;
      }
      .edit-btn{
        padding: 0.82rem 4.5rem;
        margin-right: 1rem;
      }
      .suspend-btn{
        padding: 0.82rem 3.2rem;
      }
    }
  }
 }