@media (min-width: 768px) { 
  .bv-text-input {
    input,
    select {
        padding: 8px;
    }   
}

}

@media (min-width: 992px) { 
  .bv-text-input {
    input,
    select {
        padding: 12px;
    }   
}
}