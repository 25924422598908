@media (min-width: 768px) { 
  .cs-auth-wrapper {
    .login-type {
      font-size: 1.4em;
    }
  .login-btn-typo,
    .login-register-typo{
        font-size: 1.2em;
    }
    .login-forget-pass-typo{
        font-size: 1em;
    }
  }
}

@media (min-width: 800px) {
  .cs-auth-wrapper {
  .auth-left-img-con{
    display: flex;
  }
  .auth-right-text-con{
    width: 41.66667%;
}
}
}

@media (min-width: 992px) { 
  .cs-auth-wrapper {
    .login-type {
      font-size: 1.7em;
    }
  .login-btn-typo,
    .login-register-typo{
        font-size: 1.4em;
    }
    .login-forget-pass-typo{
      font-size: 1.235em;
    }
  }
}