//
// _card.scss
//

.card {
    margin-bottom: $grid-gutter-width;
    box-shadow: $box-shadow;
    &.bordered {
        border: 1px solid #d1d1d1;
    }
}

.card-drop {
    color: $body-color;
}

.card-title {
    font-family: "Urbanist", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #222222;
}

.card-title-desc {
    color: $card-title-desc;
    margin-bottom: 24px;
}
