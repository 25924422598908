.cs-auth-wrapper {
  .login-type{ 
      font-family: 'Urbanist', sans-serif;
      font-weight: 500;
      font-size: 1.7em;
      letter-spacing: 0.03em;
      color: #000000;
      margin-left: 2.3rem;
  }
  a {
      &:focus {
          text-decoration: underline !important;
      }
  }

  /*******FORGET PASSWORD******/
  .resend-otp-typo{
      font-family: 'Urbanist', sans-serif;
      font-weight: 700;
      font-size: 1.2em;
      letter-spacing: 0.03em;
      text-decoration-line: underline !important;
  }

  .forget-vc-ip{
      margin-left: 15px;
  }
  
  .bv-forget-wrap{
    .btn{
      width: 77%;
      margin-left: 2.3rem;
    }
      .bv-auth-code {
          input:focus {
              border: 1px solid #704DE7;
          }
      }
  }
 
}
.close-modal-icon{
    color: #704DE7;
  
  }
  .header-padding{
    padding-top: 10px;
    padding-bottom: 0px;
  }
  .disable-resent{
    pointer-events: none;
  }
.reset-pass-modal-con{
    .cs-auth-wrapper{
        .bv-auth-code{
            .forget-vc-input{
                width: 4rem;
                height: 2.5rem;
                //   width: 20%;
                //   height: 3rem;
                padding: .5rem 1rem;
                border-radius: 5px;
                font-size: 1.01562rem;
                text-align: center;
                //   margin-right: 5%;
                margin-right: 16px;
                border: 1px solid #704DE7;
                text-transform: uppercase;
                background: #FFFFFF;
            }
            div{
                &:last-child{
                    margin-right: 0px;
                }
            }
        }
    }
  }