.bv-search-box {
  img{
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    left: 13px;
    top: 10px;
  }
  input{
    font-family: 'Urbanist', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.20000000298023224px;
    color: #000000;
  }
  input::placeholder{
    font-family: 'Urbanist', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.20000000298023224px;
    color: #BABABA;  
  }
}