
.add-country-modal{
  .main-header-filter-box{
    .header-filter{
      .status-header-search-admin__input-container{
        font-family: 'Urbanist', sans-serif ;
        font-size: 16px ;
        font-weight: 500 ;
        line-height: 19px ;
        letter-spacing: 0.03em;
        color: #000000 ;
        // padding-left: 5px !important  ;
      }
      .status-header-search-admin__single-value {
        font-family: 'Urbanist', sans-serif ;
        font-size: 16px ;
        font-weight: 500 ;
        line-height: 19px ;
        letter-spacing: 0.03em;
        color: #000000 ;
      }
      .error-msg{
        text-align: left;
        display: block;
      }

    }
  }
}
