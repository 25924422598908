.countries-con{
  .top-note > h5 {
    color: black;
    font-weight: 600;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-left: 16px;
  }
  .countries-table-con{
    .table{
      min-width: 636px;
    }
    .countries-id-col{
      width: 150px;
    }
    .countries-region-col{
      width: 300px;
    }
    .countries-status-col{
      width: 200px;
    }
  }
}