.recent-case-container {
    margin: 0.5rem;
    // height: 185px;
    .recent-case-card {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: #f2eeff;
        height: 48px;
        align-items: center;
        padding: 0 1rem;
        .header-id {
            font-family: "Urbanist", sans-serif;
            color: #704de7;
            font-size: 1rem;
            font-weight: 600;
        }
        .header-time {
            font-family: "Urbanist", sans-serif;
            color: #222222;
            font-size: 1rem;
            font-weight: 600;
        }
    }
    .recent-case-card-body {
        width: 100%;
        background-color: #f6f6f6;
        padding: 1rem;
        // height: 135px;

        .user-name {
            font-family: "Urbanist", sans-serif;
            font-weight: 500;
            font-size: 1rem;
            color: #222222;
        }
        .user-description {
            margin-top: 8px;
            font-family: "Urbanist", sans-serif;
            font-weight: 600;
            font-size: 1rem;
            color: #000000;
        }
        .user-case-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;

            .user-case-type {
                display: flex;
                justify-content: space-between;
                font-weight: 500;
                p {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.9rem;
                    font-family: "Urbanist", sans-serif;
                    font-weight: 700;
                    color: #000;
                }
                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;
                    background-color: #704de7;
                    border-radius: 50%;
                    height: 25px;
                    width: 25px;
                    color: #fff;
                    font-size: 0.8rem;
                }
            } 
            .case-view-button {
                // height: 25px;
                font-family: 'Urbanist', sans-serif;
                font-weight: 700;
                letter-spacing: 0.03em;
                color: #ffffff;
                background-color: #704de7;
                font-size: 15px;
                padding: 0.05rem 0.6rem;
                border: 1px solid transparent;
                &:hover {
                    background-color: transparent !important;
                    color: #704de7 !important;
                    border: 1px solid #704de7 !important;
                }
            }
        }
    }
}
.dashboard-view-all{
    color: #704de7;
    text-decoration: underline !important;
    cursor: pointer;
    font-family: 'Urbanist', sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
}
