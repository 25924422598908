.add-department-custom-modal {
    width: 400px;
}
.add-department-modal {
    img {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
    .phone-num-con {
        border: 1px solid #704de7;
        border-radius: 5px;
        width: 302px;
        height: 45px;
        input {
            font-family: "Urbanist", sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0.03em;
            color: rgba(0, 0, 0, 0.8);
            padding: 5px;
            border: none;
            height: 90%;
        }
        .PhoneInputCountry {
            border-right: 1px solid #704de7;
            padding-right: 5px;
            padding-left: 5px;
            .PhoneInputCountryIcon {
                box-shadow: none;
                width: 100%;
                height: 85%;
                background: none;
                line-height: inherit;
                .PhoneInputCountryIconImg {
                    width: 100%;
                    height: 100%;
                    border-bottom-left-radius: 5px;
                    border-top-left-radius: 5px;
                    border: 1px solid #bababa;
                }
            }
        }
        &:focus,
        &:focus-within {
            border: 1px solid #704de7;
        }
    }
    .input-group {
        border: 1px solid #704de7;
        border-radius: 5px;
        &:focus,
        &:focus-within {
            border-radius: 5px;
            border: 1px solid #704de7;
        }
        input {
            font-family: "Urbanist", sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0.03em;
            color: rgba(0, 0, 0, 0.8);
            &::placeholder {
                font-family: "Urbanist", sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0.03em;
                color: rgba(0, 0, 0, 0.5);
            }
        }
        // input{
        //   border: 1px solid #704DE7;
        //   &:focus,
        //   &:focus-within{
        //     border-radius: 5px;
        //   }
        // }
    }
    .btn {
        font-size: 1rem;
        padding: 0.65rem 0.75rem;
    }
    .btn-color {
        color: #e64a4a;
        border-color: #e64a4a;
        padding: 0.35rem 0rem;
        &:hover {
            color: #fff;
            background-color: #e64a4a;
        }
    }
    p {
        font-family: "Urbanist", sans-serif;
        font-weight: 500;
        font-size: 22px;
        text-align: center;
        color: #000;
        letter-spacing: 0.66px;
    }
    .status-header-search-admin__control {
        text-align: left;
    }
    .status-header-search-admin__placeholder {
        font-family: "Urbanist", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0.03em;
        color: rgba(0, 0, 0, 0.5);
    }
}
.countries-list-modal {
    .countries-listing {
        p {
            font-family: "Urbanist", sans-serif;
            font-size: 22px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 0px;
            text-align: left;
            color: #222222;
        }
    }
}
