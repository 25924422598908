.case-name-col{
  width: 180px;
}
.main-header-search-box {
  border-radius: 4px;
  padding: 0px;
  .form-control{
      border: 1px solid #c9b9ff;
      height: 40px;
  };
  .main-header-search-icon {
      color: #704de7; 
  }
}
.status-header-search-admin__container {
  min-width: 70%;
}
.status-header-search-admin__indicator {
  color: rgba(112, 77, 231, 1) !important;
  padding-left: 0px !important;
  padding-right: 2px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.status-header-search-admin__value-container {
  min-width: 150px;
}

.status-header-search-admin__placeholder {
    font-family: 'Urbanist', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.20000000298023224px;
    color: #BABABA !important;    
}
.status-header-search-admin__single-value {
  font-family: 'Urbanist', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.20000000298023224px;
  color: #000000;
}
.status-header-search-admin__indicators {
  min-width: inherit;
}
.status-header-search-admin__indicator-separator {
  display: none;
}

.listing-header-con {
  justify-content: flex-end;
  flex-wrap: wrap;
  .main-header-con-modified,
  .main-header-search-box-modified,
  .main-header-type-filter-modified{
      width: 100%;
  }
  .main-header-con-modified,
  .main-header-type-filter-modified{
      margin-top: 1rem;
      justify-content: unset;
      flex-direction: column;
      align-items: flex-start !important;
      .form-label{
        margin-left: 0rem !important;
      }
      .header-filter{
        width: 100%;
    }
  }
  .main-header-search-box-modified{
      div{
          width: inherit;
      }
  }
  .search-filter-con {
      margin-bottom: 0.5rem;
      justify-content: space-between;
      flex-direction: column;
      align-items: stretch !important;
      .main-header-search-box {
          width: 100%;
          margin-bottom: 0.5rem;
          margin-bottom: 1rem;
      }
      .main-header-filter-box {
          align-items: center;
          width: 100%;
          label {
              display: none;
              min-width: 80px;
              font-size: 16px;
              font-weight: 500;
              line-height: 19px;
              letter-spacing: 0.20000000298023224px;
              text-align: left;
              font-family: 'Urbanist', sans-serif;
              color: #000000;
          }
          .header-filter {
              width: 100%;
              .status-header-search-admin__placeholder{
                  font-family: "Urbanist", sans-serif;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 19px;
                  letter-spacing: 0.20000000298023224px;
                  text-align: left;
                  color: #BABABA;
              }
          }
      }
  }
  .cm-searchfilter {
      margin-bottom: 0rem;
  }
  .btn-con {
      justify-content: flex-end;
      button {
          width: 100%;
      }
  }
  .header-btn {
      padding: 0.52rem 0.75rem;
  }
}
@media (min-width: 650px) {
  .listing-header-con {
      .main-header-search-box-modified,
      .main-header-con-modified,
      .main-header-type-filter-modified{
        width: 50%;
        flex-direction: row;
        align-items: center !important;
        .form-label{
            margin-left: 1.5rem !important;
        }
        .header-filter{
            width: initial;
        }
      }
      .main-header-type-filter-modified{
          margin-top: 0;
      }
      .main-header-search-box-modified{
          div{
              width: 341px;
          }
      }
      .main-header-con-modified{
          margin-top: 1rem;
      }
      .main-header-con-modified,
  .main-header-type-filter-modified{
      justify-content: flex-end;
  }
      .search-filter-con {
          margin-bottom: 0.5rem;
          margin-bottom: 0rem;
          justify-content: space-between;
          flex-direction: row;
          align-items: center !important;
          .main-header-search-box {
              width: 330px;
              margin-bottom: 0;
          }
          .main-header-filter-box {
              justify-content: end;
              width: auto;
              label {
                  display: block;
                  min-width:80px;
              }
              .label-filterBy{
                  min-width: 70px;
              }
          }
      }
      .btn-con {
          justify-content: flex-end;
          button {
              width: 213px;
          }
      }
  }
}
@media (min-width: 850px) {
  .listing-header-con {
      .search-filter-con {
          margin-bottom: 0;
      }
      .btn-con {
          justify-content: flex-end;
      }
  }
}
@media (min-width: 992px) {
  .listing-header-con {
      .search-filter-con {
          margin-bottom: 0.5rem;
          margin-bottom: 0rem;
          justify-content: space-between;
      }
      .btn-con {
          justify-content: flex-end;
      }
  }
}
@media (min-width: 1100px) {
  .listing-header-con {
      .search-filter-con {
          margin-bottom: 0;
      }
      .btn-con {
          justify-content: flex-end;
      }
  }
}
.fitlers-con-case-mang{
  flex-direction: column;
}
@media (min-width: 1250px) {
  .fitlers-con-case-mang{
      flex-direction: row;
  }
  .listing-header-con {
      .main-header-search-box-modified{
          width: 341px;
      }
      .main-header-con-modified,
      .main-header-type-filter-modified{
          width: inherit;
          justify-content: unset;
      }
      .main-header-con-modified{
          margin-top: 0;
      }
      .main-header-search-box-modified{
          div{
              width: inherit;
          }
      }
      justify-content: flex-start;
  }
}
