.dashboard-container {
    .report-title {
        font-family: "Urbanist", sans-serif;
        font-weight: 700;
        font-size: 16px;
        color: #8566e7;
    }
    .report-description {
        font-family: "Urbanist", sans-serif;
        font-weight: 700;
        font-size: 18px;
        color: #222222;
    }
    .recent-case {
        margin: 1rem 0 0 1rem;
        font-family: "Urbanist", sans-serif;
        font-weight: 700;
        color: #222222;
        font-size: 1rem;
    }
    .chart-button {
        color: #222222;
        font-size: 15px;
        padding: 0.4rem 0.6rem;
        border: none;
        &:hover {
            background-color: #704de7;
            color: #fff;
        }
    }
    .active {
        background-color: #704de7;
        color: #fff;
    }
}
