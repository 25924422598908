.bv-btn {
    &.grad-primary {
        background: transparent linear-gradient(121deg, #7c2c83 0%, #d65189 100%);
        box-shadow: 0px 3px 12px #706e6e29;
        border-radius: 4px;
        min-width: 345px;
        min-height: 40px;
        border-color: transparent;
    }
    &.bv-btn-lg {
        min-width: 200px;
    }
    &.bv-btn-md {
        min-width: 142px;
    }
    &.bv-btn-sm {
        min-width: 129px;
    }
    &.shadow-theme {
        box-shadow: -7px 0px 10px #00000029;
        // min-width: 96px;
    }
}
