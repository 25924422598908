.reject-custom-modal {
  width: 400px;
 }
 .reject-modal {
  img{
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  .text-area-con{
    border-radius: 5px;
    border: 1px solid var(--primary, #704DE7);
    box-shadow: 0px 5px 10px 0px rgba(112, 77, 231, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    .text-area{
        height: 98%;
        width: 100%;
        resize: none;
        border: none;
        outline: none;
        cursor: auto;
         /* width */
        &::-webkit-scrollbar {
          // height: 8px;
          width: 5px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px grey; 
            border-radius: 10px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb { 
            background: #C9B9FF;
            border-radius: 10px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #C9B9FF; 
            cursor:pointer !important;

          }
          &::-webkit-scrollbar {
            cursor:pointer !important;
          }
    }

  }
  .btn {
    padding: 0.77rem 0.75rem;
    font-family: "Urbanist", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.03em;
    text-align: center;
  }
  .resume-case-btn{
    width: 205px;
    height: 50px;
    border-radius: 5px;
    background: #704DE7;
    span{
      font-family: "Urbanist", sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 3;
      letter-spacing: 0.03em;
      text-align: center;
      color: #ffffff;
    }
  }
  .btn-color {
    color: #E64A4A;
    border-color: #E64A4A;
    padding: 0.77rem 0rem;
    &:hover {
      color: #fff;
      background-color: #E64A4A;
    }
  }
  p {    
    font-family: 'Urbanist', sans-serif;    
    font-weight: 500;
    font-size: 1.5em;
    text-align: center;
    color: #000;
    letter-spacing: 0.66px;
  }
  
}