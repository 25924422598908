.earning-details-con {
    .earning-details-table-con {
        .ed-step-col {
            width: 300px;
        }
        .ed-process-col {
            width: 300px;
        }
        .ed-fees-col {
            max-width: 300px;
        }
        .table-min-height {
            min-height: auto;
        }
    }
    .earning-header-btn {
        display: flex;
        justify-content: flex-end;
        .search-btn-header-btn {
            width: 213px;
        }
    }
}

