.place-order-image-con {
  cursor: pointer;
}
.img-upload-name{
  width: 100px;
  font-size: 13px;
}
.file-upload-error{
  font-size: 13px;
}
.delete-icon{
  color: #704de7;
}