.settings-con {
    .nav-tabs {
        border: none;
    }
    .nav-tabs .nav-link {
        background-color: #fff;
        border-radius: 0px;
        transition: none;
        border-bottom: 1px solid rgba(112, 77, 231, 0.5);
        margin-bottom: 0px;
        border-top: 0px;
        border-right: 0px;
        border-left: 0px;
        font-size: 16px;
        font-family: "Urbanist", sans-serif;
        font-weight: 700;
        color: rgba(112, 77, 231, 0.5);
        padding: 1rem 2rem;
    }
    .nav-tabs .nav-link.active {
        border: none;
        color: #704de7;
        border-bottom: 1px solid #704de7;
    }
    .nav-tabs .nav-item.show .nav-link {
        border: none;
    }
    .btns-con {
        .setting-edit-profile-btn {
            padding: 0.77rem 5.95rem;
        }
        .setting-changepass-profile-btn {
            padding: 0.82rem 4.4rem;
        }
    }
}
.edit-profile-con {
    width: 400px;
    margin-left: 2rem;
    font-family: "Urbanist", sans-serif;
    color: #000;
    .phone-num-con {
        border: 1px solid #704de7;
        border-radius: 5px;
        width: 100%;
        height: 45px;
        input {
            font-family: "Urbanist", sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0.03em;
            color: rgba(0, 0, 0, 0.8);
            padding: 5px;
            border: none;
            height: 90%;
        }
        .PhoneInputCountry {
            border-right: 1px solid #704de7;
            padding-right: 5px;
            padding-left: 5px;
            .PhoneInputCountryIcon {
                box-shadow: none;
                width: 100%;
                height: 85%;
                background: none;
                line-height: inherit;
                .PhoneInputCountryIconImg {
                    width: 100%;
                    height: 100%;
                    border-bottom-left-radius: 5px;
                    border-top-left-radius: 5px;
                    border: 1px solid #bababa;
                }
            }
            
        }
        &:focus,
        &:focus-within {
            border: 1px solid #704de7;
        }
    }
    .phone-num-con-change-pass {
        .PhoneInputCountry {
            .PhoneInputCountrySelect{
                pointer-events: none;
            }
        }
    }
    .input-group {
        input {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.48px;
            color: #000;
        }
    }
    label {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.42px;
    }
    .phone-num-con {
        border: none;
        border-radius: 0px;
        width: 100%;
        border: 1px solid #704de7;
        .form-control {
            height: 48px;
            width: 100%;
            border: none;
            border-radius: 0px;
            font-family: "Urbanist", sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0.03em;
            text-align: left;
            color: rgba(0, 0, 0, 0.8);
        }
        .phone-num-btn {
            border-radius: 5px 0 0 5px;
            border: none;
            .selected-flag {
                background-color: #fff;
            }
        }
        &:focus,
        &:focus-within {
            border: 1px solid #704de7;
        }
    }
}

.setting-profile-payment{
  .bv-text-input:not(.hide-shadow){
    box-shadow: none;
  }
  .input-group
  {
    .appended-icon-pre {
        border-radius: 0px;
    }
    width: 400px;
    input{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.48px;
    color: #000;
    width: initial;
    box-shadow: 0px 3px 3px #706e6e29;
  }
  }
  label {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.42px;
  }
}
