// 
// _pagination.scss
// 

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        border-radius: 30px !important;
        margin: 0 3px !important;
        border: none;
        width: 32px;
        height: 32px;
        padding: 0;
        text-align: center;
        line-height: 32px;
    }
  }
  .pagination-square {
    .page-link {
        // border-radius: 30px !important;
        margin: 0 5px ;
        border-radius: 4px;
        border: 1px solid #C9B9FF;
        width: 35px;
        height: 35px;
        padding: 0;
        text-align: center;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.2px;

        color: #000000;
        &:hover, &:focus {
            z-index: 2;
            // color: #5a3eb9;
            background-color: #C9B9FF;
            border-color: #C9B9FF;
        }
    }
  }

