.breadcrumbs-con{
  height: 100%;
  .breadcrumbs-col{
    margin: auto 0px;
    .breadcrumb-typo-bold{
      font-weight: 600;
    }
  }
  .breadcrumb-typo{
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.6000000238418579px;
    text-align: right;
    color: #222222;
  }
}

@media (max-width: 800px) {
  .breadcrumbs-con{
  .breadcrumb-typo{
    font-size: 18px;  
  }
  .breadcrumb-img{
    width: 10px;
  }
}
}

@media (max-width: 600px) {
  .breadcrumbs-con{
    display: none !important;
  }
}