.unread-not-bg-color {
    background: #ebe8f5;
}

.unread-not-typo-color {
    color: #000;
}

.one-digit-badge {
    width: 15px;
    height: 15px;
}

.two-digit-badge {
    width: 25px;
    height: 25px;
}
.three-digit-badge {
    width: 35px;
    height: 35px;
}

.no-not-typo {
    text-align: center;
    display: block;
    margin-top: 10px;
    font-weight: bold;
    color: #000;
}

.condition {
    .simplebar-offset {
        .simplebar-content-wrapper {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
        }
    }
}
