.search-btn-header-con{
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  .margin-resp{
    margin-bottom: 1rem;
  }
  .search-btn-search-box{
    width: 100%;
    height: 40px;
    border: 1px solid #C9B9FF;
    border-radius: 4px;
    padding: 0px;
  }
  .search-btn-header-btn{
    width: 100%;
    padding: 0.52rem 0.75rem;
  }
}

@media (min-width: 650px) {
  .search-btn-header-con{
    flex-direction: row;
    .margin-resp{
      margin-bottom: 0rem;
    }
  .search-btn-search-box{
    width: 300px;
  }
  .search-btn-header-btn{
    width: 213px;
  }
}
}
